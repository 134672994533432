<template>
  <div class="sign-form">
    <div class="mb-3 alert alert-warning">
      Для подписания файлов необходимо установить плагин<br />
      <a href="https://www.cryptopro.ru/products/cades/plugin" target="_blank">
        КриптоПро ЭЦП Browser plug-in
      </a>
    </div>

    <div class="mb-3">
      <label>Выберите сертификат</label>
      <div>
        <select
          id="certificate"
          v-model="thumbprint"
          size="5"
          class="custom-select"
        >
          <option
            v-for="(cert, indx) in certificates"
            :key="indx"
            :value="cert.thumbprint"
          >
            {{ cert.name }} (действителен до: {{ formatValidTo(cert.validTo) }})
          </option>
        </select>
      </div>
    </div>

    <div>
      <div v-if="errorMessage" class="alert alert-danger mb-3">
        {{ errorMessage }}
      </div>

      <div v-if="resultMessage" class="alert alert-success mb-3">
        {{ resultMessage }}
      </div>

      <button
        class="btn btn-success"
        @click="createSignature"
        :disabled="loading"
      >
        <b-spinner v-if="loading" small></b-spinner>
        Подписать
      </button>
    </div>

    <!-- <button class="btn btn-warning" @click="verify">verify</button> -->
  </div>
</template>

<script>
import {
  // getCertificate,
  getUserCertificates,
  createHash,
  createDetachedSignature,
  createAttachedSignature,
} from "crypto-pro";
// import { auth } from "../../services/sbis";

import moment from "moment";

export default {
  name: "SignForm",
  props: {
    filepath: String,
    entry: Object,
  },
  data() {
    return {
      loading: false,
      resultMessage: null,
      errorMessage: null,
      hash: null,
      signature: null,
      message: null,
      thumbprint: null,
      certificate: null,
      signatureType: "detached", // attached, detached
      certificates: [],
    };
  },
  computed: {
    certificatesOptions() {
      return this.certificates.map((cert) => {
        return {
          text: `${cert.name} (действителен до: ${this.formatValidTo(
            cert.validTo
          )})`,
          value: cert.thumbprint,
        };
      });
    },
  },
  methods: {
    formatValidTo(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    async createSignature() {
      let hash;
      this.errorMessage = "";
      this.loading = true;

      const { thumbprint } = this;

      if (!thumbprint) {
        this.errorMessage = "Не выбран сертификат";
        this.loading = false;
        return;
      }

      // Проверяем подпись в СБИС
      const isValid = await this.verify();
      // if (isValid) {
      if (!isValid) {
        this.errorMessage = "Подпись не прошла проверку";
        this.loading = false;
        return;
      }

      const { message, signatureType } = this;

      if (!message) {
        this.errorMessage = "Не выбран файл";
        this.loading = false;
        return;
      }

      // Вычисляем хэш
      try {
        hash = await createHash(message);
        this.hash = hash;
      } catch (error) {
        this.errorMessage = error.message;
        this.loading = false;
        return;
      }

      // Создаем подпись
      if (signatureType == "detached") {
        try {
          this.signature = await createDetachedSignature(thumbprint, hash);
          this.send();
        } catch (error) {
          this.errorMessage = error.message;
        }
        this.loading = false;
        return;
      }

      try {
        this.signature = await createAttachedSignature(thumbprint, message);
        this.send();
      } catch (error) {
        this.errorMessage = error.message;
      }

      this.loading = false;
    },
    readFile(fileBlob) {
      var _this = this;
      var messageFile;

      if (!fileBlob) {
        var $messageFile = document.getElementById("messageFile");
        messageFile =
          $messageFile && $messageFile.files.length && $messageFile.files[0];
      } else {
        messageFile = fileBlob;
      }

      if (!messageFile) {
        return false;
      }

      var messagePromise = new Promise(function (resolve) {
        var fileReader = new FileReader();

        fileReader.onload = function () {
          resolve(this.result);
        };

        fileReader.readAsArrayBuffer(messageFile);
      });

      messagePromise.then(function (message) {
        _this.message = message;
      });
    },
    getFile(path) {
      fetch(path)
        .then((res) => res.blob())
        .then((blob) => {
          this.readFile(blob);
        });
    },
    async send() {
      this.loading = true;
      const { id } = this.entry;
      const data = {
        signature: this.signature,
      };

      const result = await this.$store.dispatch(
        "saveSignatureConsignmentRegister",
        { id, data }
      );

      if (result?.message) {
        this.resultMessage = result?.message;
        return;
      }

      this.errorMessage =
        "Ошибка подписания. Возможно вы уже подписывали данный документ.";
    },
    async getFilePdf() {
      const { id } = this.entry;

      const document = await this.$store.dispatch(
        "genDocumentConsignmentRegister",
        { id }
      );

      if (!document) {
        return;
      }

      let base64Data = window.btoa(unescape(encodeURIComponent(document)));
      const r = await fetch(`data:application/pdf,base64${base64Data}`);
      const blob = await r.blob();

      this.readFile(blob);
    },

    async verify() {
      return await this.$store.dispatch("verifySignatureThumbprint", {
        thumbprint: this.thumbprint,
      });
      // const certificate = await getCertificate(this.thumbprint);
      // return await certificate.isValid();
    },
  },
  mounted() {
    this.loading = true;

    getUserCertificates()
      .then((res) => {
        this.certificates = res;
        this.loading = false;
      })
      .catch((error) => {
        console.error(error);
        alert(error?.message ?? "Ошибка загрузки плагина");
        this.loading = false;
      });

    this.getFilePdf();
  },
};
</script>